import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"
import {graphql,  useStaticQuery} from "gatsby"
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 0, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2),
        }
    },
    title: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '2.2vw',
        fontWeight: 'bold',
        color: 'seashell',
        textShadow: '1px 1px 150px #080705',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vh',
            margin: '2rem 0 0 0 ',
        }
    },
    content:{
        margin:'3vw auto'
    },
    paragraphTitle:{
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '1.4vw',
        fontWeight: 'bold',
        textAlign:'center',
        margin:'1rem',
        textShadow: '0px 4px 0px #080705',
        color:'rgba(255,255,255,1)',
        [theme.breakpoints.down('sm')]: {
            fontSize: '3.5vw',
            // margin:'3vw',
        },
    },

    paragraph: {
        fontSize: '2vh',
        textAlign:'center',
        lineHeight: '3rem',
        margin:'1rem 0rem 0rem 2rem',
        color:'rgba(255,255,255,1)',
        whiteSpace:'no-wrap',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
            alignSelf:'center',
            lineHeight: '3vw',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '2vw',
            margin:'1rem',
            lineHeight: '1.5vh',
        },

    },
    paragraph2: {
        fontSize: '2.5vh',
        lineHeight: '1.5rem',
        margin:'1rem 5rem 1rem 12rem',
        color:'rgba(255,255,255,1)',

        [theme.breakpoints.down('md')]: {
            fontSize: '2vw',
            alignSelf:'center',
            margin:'1rem',
            lineHeight: '3vw',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '4vw',
            margin:'0rem',
            lineHeight: '3.5vh',
        },

    },
    link: {
        fontSize: '2.5vh',
        lineHeight: '1.5rem',
        color:'rgb(125,145,207)',

        [theme.breakpoints.down('md')]: {
            fontSize: '2vw',
            alignSelf:'center',
            lineHeight: '3vw',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.9vh',
            margin:'1vw',
            lineHeight: '3.5vh',
        },

    },
    paper:{
        margin:'1rem auto',
        backgroundColor: 'rgba(10,15,15,0.8)',
        border: 'solid 1px white',
        boxShadow: '0 10px 25px 0 rgb(157 218 213 / 50%)',
        [theme.breakpoints.down('sm')]: {
            margin:'1rem 0rem 1rem 0rem',
        },
        // border:'none',
    },
    notShowVideo:{
        margin: '5rem',
        [theme.breakpoints.down('md')]: {
            margin: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '1rem',
        },
    },
    detail:{
        display: 'inline'
    },
    placeholder: {
        width:'45%',
        left:'30%',
        [theme.breakpoints.down('xs')]: {
            height: 200,
        },
    },

}));

export default function Topics(data) {
    const classes = useStyles();
    const {nodes} = data.data.topics;

    const content = nodes.map((value, index) => (
        <>
            <Paper variant="outlined" key={index} className={classes.paper}>

                <Typography paragraph className={classes.paragraphTitle}>
                    2024-11-26<br/>
                    アコースティックギターマガジンWEBにインタビュー記事が掲載されました。
                </Typography>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.content}>
                            <Typography paragraph className={classes.paragraph}>
                                新アルバム「Beat! Beat! Beat!」についてたくさんお話しさせていただきました。<br/>
                                ぜひアルバムと一緒にお読みください！<br/>
                                <a href="https://acousticguitarmagazine.jp/interview/2024-1108-aoi-michele-interview/">
                                    https://acousticguitarmagazine.jp/interview/2024-1108-aoi-michele-interview/
                                </a>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Paper variant="outlined" key={index} className={classes.paper}>

                <Typography paragraph className={classes.paragraphTitle}>
                    2024-05-11<br/>
                    出演情報
                </Typography>
                <Grid container spacing={0}>
                        <Grid item xs={12} sm={12}>
                            <div className={classes.content}>
                                <Typography paragraph className={classes.paragraph}>
                                    【日時】2024年5月11日(土)16:45~17:00<br/>
                                    【会場】サウンドメッセ in OSAKA 2024<br/>
                                    Taylor Guitars ブースにて演奏させていただきます。<br/>
                                    <a href="https://sound-messe.com/archives/news/2023914_1">
                                        https://sound-messe.com/archives/news/2023914_1
                                    </a>
                                </Typography>
                            </div>
                        </Grid>
                </Grid>
            </Paper>
            <Paper variant="outlined" key={index} className={classes.paper}>

                <Typography paragraph className={classes.paragraphTitle}>
                    2024-04-11<br/>
                    ゲスト出演決定!
                </Typography>
                <Grid container spacing={0}>
                    {value.childMarkdownRemark.frontmatter.detail !== '' ?
                        <Grid item xs={12} sm={12}>
                            <div className={classes.content}>
                                <Typography paragraph className={classes.paragraph}>
                                    4月12日(金)24時00分~24時30分<br/>
                                    MBSラジオ「押尾コータローの押しても弾いても」にAoi MichellE ゲスト出演決定
                                    <br/>
                                    <a href="https://www.mbs1179.com/oshio/radiko">
                                        https://www.mbs1179.com/oshio/radiko</a>
                                    <br/>
                                    <a href="https://radiko.jp/mobile/events/9779653">
                                        https://radiko.jp/mobile/events/9779653</a>
                                    <br/>
                                </Typography>
                            </div>
                        </Grid> : null}
                    {/*{value.childMarkdownRemark.frontmatter.videoTitle=== "" ?*/}
                    {/*<Grid item xs={12} sm={12}>*/}
                    {/*    <Img fluid={data.data.placeholderImage3.childImageSharp.fluid} className={classes.placeholder}/>*/}
                    {/*    <Img fluid={data.data.placeholderImage4.childImageSharp.fluid} className={classes.placeholder}/>*/}
                    {/*</Grid> :*/}
                    {/*<Grid item xs={12} sm={12}>*/}
                    {/*    <Video*/}
                    {/*        videoSrcURL={value.childMarkdownRemark.frontmatter.videoSourceURL}*/}
                    {/*        videoTitle={value.childMarkdownRemark.frontmatter.videoTitle}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*}*/}
                </Grid>
            </Paper>
            <Paper variant="outlined" key={index} className={classes.paper}>

                <Typography paragraph className={classes.paragraphTitle}>
                    2024-03-31<br/>
                    キヤノンPowerShot V10 第2期 アンバサダーに就任
                </Typography>
                <Grid container spacing={0}>
                    {value.childMarkdownRemark.frontmatter.detail !== '' ?
                        <Grid item xs={12} sm={12}>
                            <div className={classes.content}>
                                <Typography paragraph className={classes.paragraph}>
                                    Canonから新登場のポケットサイズVlogカメラ PowerShot V10 “アコギ×旅” や演奏でPowerShot V10の魅力を伝えていきます。<br/>
                                    Aoi MichellE 各種SNSにて発信中<br/>
                                    <br/>
                                    <a href="https://psv10.tabippo.net/ambassador/member/">
                                        https://psv10.tabippo.net/ambassador/member/</a>
                                    <br/>
                                </Typography>
                            </div>
                        </Grid> : null}
                    {/*{value.childMarkdownRemark.frontmatter.videoTitle=== "" ?*/}
                    <Grid item xs={12} sm={12}>
                        <Img fluid={data.data.placeholderImage3.childImageSharp.fluid} className={classes.placeholder}/>
                        <Img fluid={data.data.placeholderImage4.childImageSharp.fluid} className={classes.placeholder}/>
                    </Grid> :
                    {/*<Grid item xs={12} sm={12}>*/}
                    {/*    <Video*/}
                    {/*        videoSrcURL={value.childMarkdownRemark.frontmatter.videoSourceURL}*/}
                    {/*        videoTitle={value.childMarkdownRemark.frontmatter.videoTitle}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*}*/}
                </Grid>
            </Paper>
            <Paper variant="outlined" key={index} className={classes.paper}>

                <Typography paragraph className={classes.paragraphTitle}>
                    2024-03-12<br/>
                    クラウドファウンディング初挑戦中!【新アルバム制作プロジェクト】
                </Typography>
                <Grid container spacing={0}>
                    {value.childMarkdownRemark.frontmatter.detail !== '' ?
                        <Grid item xs={12} sm={12}>
                            <div className={classes.content}>
                                <Typography paragraph className={classes.paragraph}>
                                    2024年3/9(土) ~5/27(月)<br/>
                                    女性ソロギタリストAoi MichellEの存在を知ってもらいたい! <br/>
                                    もっとたくさんの人に届けたい!今の音楽をカタチに残したい!<br/>
                                    という思いから新アルバム制作プロジェクトを立ち上げました! <br/>
                                    皆様にも楽しんでいただけるアルバムを作りたいと思っています。 <br/>
                                    応援いただけたら嬉しいです。よろしくお願いします!<br/>
                                    <a href="https://camp-fire.jp/projects/view/733998?utm_campaign=cp_po_share_c_msg_mypage_projects_show">
                                        https://camp-fire.jp/projects/view/733998?utm_campaign=cp_po_share_c_msg_mypage_projects_show</a>
                                    <br/>
                                </Typography>
                            </div>
                        </Grid> : null}
                    {/*{value.childMarkdownRemark.frontmatter.videoTitle=== "" ?*/}
                    <Grid item xs={12} sm={12}>
                        <Img fluid={data.data.placeholderImage2.childImageSharp.fluid} className={classes.placeholder}/>
                    </Grid> :
                    {/*<Grid item xs={12} sm={12}>*/}
                    {/*    <Video*/}
                    {/*        videoSrcURL={value.childMarkdownRemark.frontmatter.videoSourceURL}*/}
                    {/*        videoTitle={value.childMarkdownRemark.frontmatter.videoTitle}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*}*/}
                </Grid>
            </Paper>
        <Paper variant="outlined" key={index} className={classes.paper}>

            <Typography paragraph className={classes.paragraphTitle}>
                {value.childMarkdownRemark.frontmatter.date} <br/>
                {value.childMarkdownRemark.frontmatter.title}
            </Typography>
            <Grid container spacing={0}>
                {value.childMarkdownRemark.frontmatter.detail !== '' ?
                    <Grid item xs={12} sm={12}>
                        <div className={classes.content}>
                            <Typography paragraph className={classes.paragraph}>
                                {/*{value.childMarkdownRemark.frontmatter.detail}<br/>*/}
                                {/*{value.childMarkdownRemark.frontmatter.detail2}*/}
                                クラシック・ギターの専門誌、月刊「現代ギター」2023年6月号(No.717)<br/>
                                アコギ談義のコーナーにAoi MichellEのインタビュー記事(4p)、<br/>
                                オリジナル楽曲 “Fire”のTAB譜が掲載されました。<br/>
                                <a  href=" https://www.gendaiguitar.com/index.php?main_page=product_info&products_id=144665"> https://www.gendaiguitar.com/index.php?main_page=product_info&products_id=144665</a>
                            </Typography>
                        </div>
                    </Grid> : null}
                {/*{value.childMarkdownRemark.frontmatter.videoTitle=== "" ?*/}
                    <Grid item xs={12} sm={12}>
                    <Img fluid={data.data.placeholderImage.childImageSharp.fluid} className={classes.placeholder}/>
                    </Grid> :
                    {/*<Grid item xs={12} sm={12}>*/}
                    {/*    <Video*/}
                    {/*        videoSrcURL={value.childMarkdownRemark.frontmatter.videoSourceURL}*/}
                    {/*        videoTitle={value.childMarkdownRemark.frontmatter.videoTitle}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
            {/*}*/}
            </Grid>
        </Paper>

        </>
        )
    );



    return (
        <Layout  bgImage={true}>
            <SEO title="topics"/>
            <Container maxWidth="lg" component="main" className={classes.root}>
                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                    Topics
                </Typography>

                {content}
            </Container>
        </Layout>
    )
};

export const query = graphql`
    {
    topics: allFile(sort: {fields: [childMarkdownRemark___frontmatter___date], order: DESC}, filter: {sourceInstanceName: {eq: "youtubeMarkDown"}}) {
        nodes {
            id
            childMarkdownRemark {
                id
                frontmatter {
                  date
                  path
                  title
                  detail
                  detail2
                  videoSourceURL
                  videoTitle
                }
            }
        }
    }
    placeholderImage: file(relativePath: {eq: "IMG_2281.jpg"}) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
    placeholderImage2: file(relativePath: {eq: "cloud_funding.png"}) {
        childImageSharp {
            fluid(quality: 65, maxWidth: 1100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    },
        placeholderImage3: file(relativePath: {eq: "canon_1.jpg"}) {
            childImageSharp {
                fluid(quality: 65, maxWidth: 1100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        },
        placeholderImage4: file(relativePath: {eq: "canon_2.jpg"}) {
            childImageSharp {
                fluid(quality: 65, maxWidth: 1100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        },
}
`
